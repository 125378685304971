//
// Extend from bootstrap
//

// Table

.table-invoice.card {
  border-radius: 0.625rem;
  .table {
    margin-bottom: 0rem;
  }
  .table thead th {
    vertical-align: middle;
    border-bottom: 1px solid var(--#{$prefix}gray-200);
    padding: 1rem 1.25rem;
  }
  .table th,
  .table td {
    padding: 0.4375rem;
    vertical-align: top;
    border-top: 1px solid var(--#{$prefix}gray-200);
    font-size: 0.875rem;
  }
  .table td {
    padding: 1.0625rem;
  }
  .table tr:first-child th:first-child {
    border-top-left-radius: 0.625rem;
  }
  .table tr:first-child th:last-child {
    border-top-right-radius: 0.625rem;
  }
  .table tr:first-child th,
  .card .table tr:first-child td {
    border-top: 0px;
  }
}

// table lg

.table-lg {
  th,
  td {
    padding: $table-cell-padding-y-lg $table-cell-padding-x-lg;
  }
}

// Table centered
.table-centered td,
.table-centered th {
  vertical-align: middle !important;
}

// Table with Checkbox
.table-with-checkbox > tbody > tr > td:first-child,
.table-with-checkbox > thead > tr > th:first-child {
  width: 1px;
  padding-right: 0px;
}

// table card for negative margin in body.
.table-card {
  margin: -1.5rem;
}

// datatable
// .table th {
//   color: var(--#{$prefix}gray-700);
// }

table tr:last-child td {
  border-bottom-width: 0px;
}
