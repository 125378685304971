// list

.list-group-hover {
    &:hover,
    &:focus {
        background-color: transparent;
        color: var(--#{$prefix}gray-900);
        .list-group-title {
            color: var(--#{$prefix}primary);
        }
    }
}
