// jsvectormap
// Class prefix
$classPrefix: jvm- !default;
$border-color: var(--#{$prefix}gray-300) !default;
$box-shadow: $box-shadow-sm;

// Tooltip
$tooltip-shadow: $box-shadow-sm;
$tooltip-font-family: "Inter", "sans-serif" !default;
$tooltip-bg-color: var(--#{$prefix}white) !default;
$tooltip-radius: 3px !default;
$tooltip-font-size: smaller !default;
$tooltip-padding: 3px 5px !default;

// Zoom buttons
$zoom-btns-bg-color: var(--#{$prefix}white) !default;
$zoom-btns-radius: 3px !default;
$zoom-btns-height: 15px !default;
$zoom-btns-padding: 6px !default;
$zoom-btns-width: 15px !default;

// Series
$series-container-right: 15px !default;

// Legends
$legend-background-color: var(--#{$prefix}white) !default;
$legend-radius: 0.25rem !default;
$legend-margin-left: 0.75rem !default;
$legend-padding: 0.6rem !default;

// Legend title
$legend-title-padding-bottom: 0.5rem !default;
$legend-title-margin-bottom: 0.575rem !default;

// Legend ticks
$legend-tick-margin-top: 0.575rem !default;
$legend-tick-sample-radius: 4px !default;
$legend-tick-sample-height: 16px !default;
$legend-tick-sample-width: 16px !default;
$legend-tick-text-font-size: 12px !default;
$legend-tick-text-margin-top: 3px !default;

.jvm-region.jvm-element {
    fill: var(--#{$prefix}gray-200);
}
