// Bootstrap select

.bootstrap-select {
  > .dropdown-toggle {
    background-color: var(--#{$prefix}white) !important;
    border-color: var(--#{$prefix}gray-300) !important;
    font-weight: 400;
    &:focus {
      outline: 0px;
    }
  }
  > select.mobile-device:focus + .dropdown-toggle,
  .dropdown-toggle:focus {
    outline: 0px !important;
  }
  .bs-placeholder {
    color: var(--#{$prefix}gray-500) !important;
    &:hover,
    &:focus,
    &:active {
      color: var(--#{$prefix}gray-500) !important;
    }
  }
}
