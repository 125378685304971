// noui target

.noUi-target {
  background-color: var(--#{$prefix}gray-100);
  border-radius: 0.5rem;
  border: none;
  box-shadow: none;
}

.noUi-horizontal {
  height: 8px;
}

.noUi-handle {
  border: transparent;
  border-radius: 100%;
  background: var(--#{$prefix}white);
  cursor: default;
  box-shadow: $box-shadow-lg;
}
.noUi-handle:focus {
  outline: none;
}

.noUi-horizontal .noUi-handle {
  width: 18px;
  height: 18px;
  right: -13px;
  top: -6px;
}
.noUi-handle:after,
.noUi-handle:before {
  display: none;
}
.noUi-connects {
  border-radius: 6px;
}

.noUi-connect {
  background: var(--#{$prefix}primary);
}
.noUi-tooltip {
  border: none;
  padding: 0;
  background-color: transparent;
}

.noUi-tooltip {
  border: none;
  padding: 0;
  background-color: var(--#{$prefix}primary);
  height: 30px;
  width: 30px;
  line-height: 30px;
  color: var(--#{$prefix}white);
}
